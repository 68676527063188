import React from "react";
import { graphql } from "gatsby"
import Seo from "../components/seo";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ContactPage = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Seo language={"en"} alternate={"/it/contatti"} />
      <div class="flex items-center justify-center py-8 md:p-12">
        <h1 className="text-3xl">Contact</h1>
        <div class="mx-auto w-full max-w-xl">
          <form name="contact" method="POST" data-netlify="true" data-netlify-recaptcha="true" netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
            <div class="mb-5">
              <label
                for="name"
                class="mb-3 block text-base font-medium text-gray-700 dark:text-gray-300"
              >
                {t('full_name')}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder={t('full_name')}
                required
                class="w-full rounded-lg py-3 px-6 text-base font-medium text-gray-400 outline-none focus:shadow-md bg-gray-100 dark:bg-gray-700"
              />
            </div>
            <div class="mb-5">
              <label
                for="email"
                class="mb-3 block text-base font-medium text-gray-700 dark:text-gray-300"
              >
                {t('email')}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder={t('email_placeholder')}
                required
                class="w-full rounded-lg py-3 px-6 text-base font-medium text-gray-400 outline-none focus:shadow-md bg-gray-100 dark:bg-gray-700"
              />
            </div>
            <div class="mb-5">
              <label
                for="subject"
                class="mb-3 block text-base font-medium text-gray-700 dark:text-gray-300"
              >
                {t('subject')}
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder={t('subject_placeholder')}
                class="w-full rounded-lg py-3 px-6 text-base font-medium text-gray-400 outline-none focus:shadow-md bg-gray-100 dark:bg-gray-700"
              />
            </div>
            <div class="mb-5">
              <label
                for="message"
                class="mb-3 block text-base font-medium text-gray-700 dark:text-gray-300"
              >
                {t('message')}
              </label>
              <textarea
                rows="4"
                name="message"
                id="message"
                placeholder={t('message_placeholder')}
                required
                class="w-full resize-none rounded-lg py-3 px-6 text-base font-medium text-gray-400 outline-none focus:shadow-md bg-gray-100 dark:bg-gray-700"
              ></textarea>
            </div>
            <div>
              <button class="hover:shadow-form rounded-lg bg-sky-700 text-white py-3 px-8 text-base font-semibold outline-none">
                {t('submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`